import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CarrierPositions from "../components/career-positions"
import Contact from "../components/contact"

const CareerPage = () => {
  return (
    <Layout location="/career" swiper={false}>
      <SEO />
      {/* <Team /> */}
      {/* <PeopleSays /> */}
      <CarrierPositions />
      <Contact />
    </Layout>
  )
}
export default CareerPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
