import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const CarrierPositions = () => {
  const { t } = useTranslation()

  return (
    // <!-- CarrierPositions-->
    <section
      className="section section-sm section-fluid bg-default text-center"
      id="positions"
      data-type="anchor"
    >
      <div className="container">
        <h2 className="wow fadeInLeft">Csatlakozz hozzánk!</h2>
        <p className="quote-jean wow fadeInRight" data-wow-delay=".1s">
          <a
            className="button button-primary button-ujarak"
            href="mailto:career@mxcsoftware.it?subject=Állás"
          >
            Jelentkezz ITT
          </a>
        </p>
        <div className="isotope-filters isotope-filters-horizontal">
          <button
            className="isotope-filters-toggle button button-md button-icon button-icon-right button-default-outline button-wapasha"
            data-custom-toggle="#isotope-3"
            data-custom-toggle-hide-on-blur="true"
          >
            <span className="icon fa fa-caret-down"></span>
            {t("filter")}
          </button>
          <ul className="isotope-filters-list" id="isotope-3">
            <li>
              <a
                className="active"
                href="#"
                data-isotope-filter="*"
                data-isotope-group="gallery"
              >
                {t("all_filter")}
              </a>
            </li>
            <li>
              <a
                href="#"
                data-isotope-filter="backend"
                data-isotope-group="gallery"
              >
                Backend
              </a>
            </li>
            <li>
              <a
                href="#"
                data-isotope-filter="frontend"
                data-isotope-group="gallery"
              >
                Frontend
              </a>
            </li>
            <li>
              <a
                href="#"
                data-isotope-filter="fullstack"
                data-isotope-group="gallery"
              >
                Fullstack
              </a>
            </li>
            {/* <li>
            <a
              href="#"
              data-isotope-filter="mobile"
              data-isotope-group="gallery"
            >
              Mobil
            </a>
          </li> */}
          </ul>
        </div>
        <div
          className="row row-30 isotope ml-auto mr-auto"
          data-isotope-layout="vertical"
          data-isotope-group="gallery"
        >
          {/* Backend senior */}
          <div
            className="col-12 isotope-item wow fadeInRight"
            data-filter="backend"
            data-wow-delay=".1s"
          >
            <div
              className="offset-xl-2 col-xl-8 wow fadeInRight"
              data-wow-delay=".1s"
            >
              <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
                <div>
                  <h3 className="box-icon-modern-big-title">
                    Senior .NET backend fejlesztő
                  </h3>
                  <div className="box-icon-modern-decor"></div>
                  <h4 className="text-left">Elvárások</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Minimum 5 év szakmai tapasztalat .NET technológiákkal
                    </li>
                    <li className="list-group-item">
                      Legalább 1 SQL adatbázis alapos ismerete + ORM
                    </li>
                    <li className="list-group-item">
                      Legalább egy felhőalapú rendszer alaposabb ismerete
                      (Azure, Amazon, Google Cloud)
                    </li>
                    <li className="list-group-item">
                      GIT, GitFlow alapos ismerete
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Előnyök</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">NoSQL adatbázis ismeret</li>
                    <li className="list-group-item">
                      Micro-service architektúra alaposabb ismerete
                    </li>
                    <li className="list-group-item">
                      Csapatvezetési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Bármely frontend technológia ismerete és full-stack
                      gondolkodás
                    </li>
                    <li className="list-group-item">DDD, CQRS ismeretek</li>
                    <li className="list-group-item">
                      Integrációs és unit tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Devops ismeretek (Azure Devops/Jenkins/GitLab)
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Amit kínálunk</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Versenyképes fizetés és juttatások
                    </li>
                    <li className="list-group-item">
                      A fentieken kívül, pozíciótól függően éves/negyedéves
                      jutalom kerül kifizetésre, továbbá kollégáink
                      projektteljesítési bónuszokban is részesülhetnek
                    </li>
                    <li className="list-group-item">
                      Szakmai fejlődést maximálisan támogató céges folyamatok és
                      tapasztalt kollégáink nagy hangsúlyt fektetnek a
                      mentorálásra
                    </li>
                    <li className="list-group-item">
                      Rugalmas munkaidő és home office lehetőség
                    </li>
                    <li className="list-group-item">
                      Fiatalos, pörgős csapat és változatos projektek a kisebb
                      alkalmazásoktól a komplexebb felhő alapú rendszerekig
                    </li>
                    <li className="list-group-item">Rendszeres csapatépítők</li>
                  </ul>

                  <a
                    className="button button-primary button-ujarak"
                    href="mailto:career@mxcsoftware.it?subject=Állás"
                  >
                    Jelentkezz ITT
                  </a>
                </div>
              </article>
            </div>
          </div>
          {/* Backend medior */}
          <div
            className="offset-xl-2 col-xl-8 isotope-item wow fadeInRight"
            data-filter="backend"
            data-wow-delay=".1s"
          >
            <div className="col-sm-12 wow fadeInRight" data-wow-delay=".1s">
              <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
                <div>
                  <h3 className="box-icon-modern-big-title">
                    Medior .NET backend fejlesztő
                  </h3>
                  <div className="box-icon-modern-decor"></div>
                  <h4 className="text-left">Elvárások</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Minimum 3 év szakmai tapasztalat .NET technológiákkal
                    </li>
                    <li className="list-group-item">
                      Legalább 1 SQL adatbázis ismerete + ORM
                    </li>
                    <li className="list-group-item">GIT alapos ismerete</li>
                  </ul>
                  <h4 className="text-left mt-5">Előnyök</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Legalább egy felhőalapú rendszer alaposabb ismerete
                      (Azure, Amazon, Google Cloud)
                    </li>
                    <li className="list-group-item">NoSQL adatbázis ismeret</li>
                    <li className="list-group-item">
                      Micro-service architektúra alaposabb ismerete
                    </li>
                    <li className="list-group-item">
                      Csapatvezetési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Bármely frontend technológia ismerete és full-stack
                      gondolkodás
                    </li>
                    <li className="list-group-item">DDD, CQRS ismeretek</li>
                    <li className="list-group-item">
                      Integrációs és unit tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Devops ismeretek (Azure Devops/Jenkins/GitLab)
                    </li>
                    <li className="list-group-item">GitFlow ismerete</li>
                  </ul>
                  <h4 className="text-left mt-5">Amit kínálunk</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Versenyképes fizetés és juttatások
                    </li>
                    <li className="list-group-item">
                      A fentieken kívül, pozíciótól függően éves/negyedéves
                      jutalom kerül kifizetésre, továbbá kollégáink
                      projektteljesítési bónuszokban is részesülhetnek
                    </li>
                    <li className="list-group-item">
                      Szakmai fejlődést maximálisan támogató céges folyamatok és
                      tapasztalt kollégáink nagy hangsúlyt fektetnek a
                      mentorálásra
                    </li>
                    <li className="list-group-item">
                      Rugalmas munkaidő és home office lehetőség
                    </li>
                    <li className="list-group-item">
                      Fiatalos, pörgős csapat és változatos projektek a kisebb
                      alkalmazásoktól a komplexebb felhő alapú rendszerekig
                    </li>
                    <li className="list-group-item">Rendszeres csapatépítők</li>
                  </ul>

                  <a
                    className="button button-primary button-ujarak"
                    href="mailto:career@mxcsoftware.it?subject=Állás"
                  >
                    Jelentkezz ITT
                  </a>
                </div>
              </article>
            </div>
          </div>
          {/* Backend junior */}
          {/* <div
          className="col-12 isotope-item wow fadeInRight"
          data-filter="backend"
          data-wow-delay=".1s"
        >
          <div
            className="offset-xl-2 col-xl-8 wow fadeInRight"
            data-wow-delay=".1s"
          >
            <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
              <div>
                <h3 className="box-icon-modern-big-title">
                  Junior .NET backend fejlesztő
                </h3>
                <div className="box-icon-modern-decor"></div>
                <h4 className="text-left">Elvárások</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Fejlesztői végzettség vagy minimum 1 év szakmai tapasztalat
                  </li>
                </ul>
                <h4 className="text-left mt-5">Előnyök</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Adatbázisok ismerete + ORM
                  </li>
                  <li className="list-group-item">
                    Micro-service architektúra alaposabb ismerete
                  </li>
                  <li className="list-group-item">
                    Bármely frontend technológia ismerete és full-stack
                    gondolkodás
                  </li>
                  <li className="list-group-item">GIT verziókezelő ismerete</li>
                </ul>
                <h4 className="text-left mt-5">Amit kínálunk</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Versenyképes fizetés és juttatások
                  </li>
                  <li className="list-group-item">
                    A fentieken kívül, pozíciótól függően éves/negyedéves
                    jutalom kerül kifizetésre, továbbá kollégáink
                    projektteljesítési bónuszokban is részesülhetnek
                  </li>
                  <li className="list-group-item">
                    Szakmai fejlődést maximálisan támogató céges folyamatok és
                    tapasztalt kollégáink nagy hangsúlyt fektetnek a
                    mentorálásra
                  </li>
                  <li className="list-group-item">
                    Rugalmas munkaidő és home office lehetőség
                  </li>
                  <li className="list-group-item">
                    Fiatalos, pörgős csapat és változatos projektek a kisebb
                    alkalmazásoktól a komplexebb felhő alapú rendszerekig
                  </li>
                  <li className="list-group-item">Rendszeres csapatépítők</li>
                </ul>

                <a
                  className="button button-primary button-ujarak"
                  href="mailto:career@mxcsoftware.it?subject=Állás"
                >
                  Jelentkezz ITT
                </a>
              </div>
            </article>
          </div>
        </div> */}

          {/* Frotnend senior */}
          <div
            className="col-12 isotope-item wow fadeInRight"
            data-filter="frontend"
            data-wow-delay=".1s"
          >
            <div
              className="offset-xl-2 col-xl-8 wow fadeInRight"
              data-wow-delay=".1s"
            >
              <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
                <div>
                  <h3 className="box-icon-modern-big-title">
                    Senior frontend fejlesztő
                  </h3>
                  <div className="box-icon-modern-decor"></div>
                  <h4 className="text-left">Elvárások</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Minimum 5 év szakmai tapasztalat Angular 2+ és/vagy React
                      technológiával
                    </li>
                    <li className="list-group-item">
                      Legalább 2 év TypeScript tapasztalat
                    </li>
                    <li className="list-group-item">RxJS tapasztalat</li>
                    <li className="list-group-item">
                      Redux és/vagy NgRx tapasztalat
                    </li>
                    <li className="list-group-item">
                      Bootstrap, Material, RESTful API, HTML5, CSS, JWT &
                      security, I18n, Yarn, NPM
                    </li>
                    <li className="list-group-item">
                      Unit és/vagy Component tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      GIT, GitFlow alapos ismerete
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Előnyök</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      UI és/vagy UX tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Csapatvezetési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Bármely backend technológia ismerete és full-stack
                      gondolkodás
                    </li>
                    <li className="list-group-item">
                      React Native és mobilfejlesztési tapasztalatok
                    </li>
                    <li className="list-group-item">DDD, CQRS ismeretek</li>
                    <li className="list-group-item">
                      Devops ismeretek (Azure Devops/Jenkins/GitLab)
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Amit kínálunk</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Versenyképes fizetés és juttatások
                    </li>
                    <li className="list-group-item">
                      A fentieken kívül, pozíciótól függően éves/negyedéves
                      jutalom kerül kifizetésre, továbbá kollégáink
                      projektteljesítési bónuszokban is részesülhetnek
                    </li>
                    <li className="list-group-item">
                      Szakmai fejlődést maximálisan támogató céges folyamatok és
                      tapasztalt kollégáink nagy hangsúlyt fektetnek a
                      mentorálásra
                    </li>
                    <li className="list-group-item">
                      Rugalmas munkaidő és home office lehetőség
                    </li>
                    <li className="list-group-item">
                      Fiatalos, pörgős csapat és változatos projektek a kisebb
                      alkalmazásoktól a komplexebb felhő alapú rendszerekig
                    </li>
                    <li className="list-group-item">Rendszeres csapatépítők</li>
                  </ul>

                  <a
                    className="button button-primary button-ujarak"
                    href="mailto:career@mxcsoftware.it?subject=Állás"
                  >
                    Jelentkezz ITT
                  </a>
                </div>
              </article>
            </div>
          </div>
          {/* Frontend medior */}
          <div
            className="offset-xl-2 col-xl-8 isotope-item wow fadeInRight"
            data-filter="frontend"
            data-wow-delay=".1s"
          >
            <div className="col-sm-12 wow fadeInRight" data-wow-delay=".1s">
              <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
                <div>
                  <h3 className="box-icon-modern-big-title">
                    Medior frontend fejlesztő
                  </h3>
                  <div className="box-icon-modern-decor"></div>
                  <h4 className="text-left">Elvárások</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Minimum 3 év szakmai tapasztalat Angular 2+ és/vagy React
                      technológiával
                    </li>
                    <li className="list-group-item">
                      Legalább 1 év TypeScript tapasztalat
                    </li>
                    <li className="list-group-item">
                      Redux és/vagy NgRx tapasztalat
                    </li>
                    <li className="list-group-item">
                      GIT, GitFlow alapos ismerete
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Előnyök</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">RxJS tapasztalat</li>
                    <li className="list-group-item">
                      Bootstrap, Material, RESTful API, HTML5, CSS, JWT &
                      security, I18n, Yarn, NPM
                    </li>
                    <li className="list-group-item">
                      Unit és/vagy Component tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      UI és/vagy UX tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Csapatvezetési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Bármely backend technológia ismerete és full-stack
                      gondolkodás
                    </li>
                    <li className="list-group-item">
                      React Native és mobilfejlesztési tapasztalatok
                    </li>
                    <li className="list-group-item">DDD, CQRS ismeretek</li>
                    <li className="list-group-item">
                      Integrációs és unit tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Devops ismeretek (Azure Devops/Jenkins/GitLab)
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Amit kínálunk</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Versenyképes fizetés és juttatások
                    </li>
                    <li className="list-group-item">
                      A fentieken kívül, pozíciótól függően éves/negyedéves
                      jutalom kerül kifizetésre, továbbá kollégáink
                      projektteljesítési bónuszokban is részesülhetnek
                    </li>
                    <li className="list-group-item">
                      Szakmai fejlődést maximálisan támogató céges folyamatok és
                      tapasztalt kollégáink nagy hangsúlyt fektetnek a
                      mentorálásra
                    </li>
                    <li className="list-group-item">
                      Rugalmas munkaidő és home office lehetőség
                    </li>
                    <li className="list-group-item">
                      Fiatalos, pörgős csapat és változatos projektek a kisebb
                      alkalmazásoktól a komplexebb felhő alapú rendszerekig
                    </li>
                    <li className="list-group-item">Rendszeres csapatépítők</li>
                  </ul>

                  <a
                    className="button button-primary button-ujarak"
                    href="mailto:career@mxcsoftware.it?subject=Állás"
                  >
                    Jelentkezz ITT
                  </a>
                </div>
              </article>
            </div>
          </div>
          {/* Fullstack senior */}
          <div
            className="col-12 isotope-item wow fadeInRight"
            data-filter="fullstack"
            data-wow-delay=".1s"
          >
            <div
              className="offset-xl-2 col-xl-8 wow fadeInRight"
              data-wow-delay=".1s"
            >
              <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
                <div>
                  <h3 className="box-icon-modern-big-title">
                    Senior fullstack fejlesztő
                  </h3>
                  <div className="box-icon-modern-decor"></div>
                  <h4 className="text-left">Elvárások</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Minimum 5 év szakmai tapasztalat
                    </li>
                    <li className="list-group-item">
                      Legalább 2 év .NET backend tapasztalat
                    </li>
                    <li className="list-group-item">
                      Unit és/vagy Integrációs tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      GIT, GitFlow alapos ismerete
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Előnyök</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      UI és/vagy UX tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Csapatvezetési tapasztalatok
                    </li>
                    <li className="list-group-item">DDD, CQRS ismeretek</li>
                    <li className="list-group-item">
                      Devops ismeretek (Azure Devops/Jenkins/GitLab)
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Amit kínálunk</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Versenyképes fizetés és juttatások
                    </li>
                    <li className="list-group-item">
                      A fentieken kívül, pozíciótól függően éves/negyedéves
                      jutalom kerül kifizetésre, továbbá kollégáink
                      projektteljesítési bónuszokban is részesülhetnek
                    </li>
                    <li className="list-group-item">
                      Szakmai fejlődést maximálisan támogató céges folyamatok és
                      tapasztalt kollégáink nagy hangsúlyt fektetnek a
                      mentorálásra
                    </li>
                    <li className="list-group-item">
                      Rugalmas munkaidő és home office lehetőség
                    </li>
                    <li className="list-group-item">
                      Fiatalos, pörgős csapat és változatos projektek a kisebb
                      alkalmazásoktól a komplexebb felhő alapú rendszerekig
                    </li>
                    <li className="list-group-item">Rendszeres csapatépítők</li>
                  </ul>

                  <a
                    className="button button-primary button-ujarak"
                    href="mailto:career@mxcsoftware.it?subject=Állás"
                  >
                    Jelentkezz ITT
                  </a>
                </div>
              </article>
            </div>
          </div>
          {/* Fullstack medior */}
          <div
            className="offset-xl-2 col-xl-8 isotope-item wow fadeInRight"
            data-filter="fullstack"
            data-wow-delay=".1s"
          >
            <div className="col-sm-12 wow fadeInRight" data-wow-delay=".1s">
              <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
                <div>
                  <h3 className="box-icon-modern-big-title">
                    Medior fullstack fejlesztő
                  </h3>
                  <div className="box-icon-modern-decor"></div>
                  <h4 className="text-left">Elvárások</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Minimum 3 év szakmai tapasztalat
                    </li>
                    <li className="list-group-item">
                      GIT, GitFlow alapos ismerete
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Előnyök</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Unit és/vagy Integrációs tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      UI és/vagy UX tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Csapatvezetési tapasztalatok
                    </li>
                    <li className="list-group-item">DDD, CQRS ismeretek</li>
                    <li className="list-group-item">
                      Integrációs és unit tesztelési tapasztalatok
                    </li>
                    <li className="list-group-item">
                      Devops ismeretek (Azure Devops/Jenkins/GitLab)
                    </li>
                  </ul>
                  <h4 className="text-left mt-5">Amit kínálunk</h4>
                  <ul className="text-left ml-auto list-group list-group-flush">
                    <li className="list-group-item">
                      Versenyképes fizetés és juttatások
                    </li>
                    <li className="list-group-item">
                      A fentieken kívül, pozíciótól függően éves/negyedéves
                      jutalom kerül kifizetésre, továbbá kollégáink
                      projektteljesítési bónuszokban is részesülhetnek
                    </li>
                    <li className="list-group-item">
                      Szakmai fejlődést maximálisan támogató céges folyamatok és
                      tapasztalt kollégáink nagy hangsúlyt fektetnek a
                      mentorálásra
                    </li>
                    <li className="list-group-item">
                      Rugalmas munkaidő és home office lehetőség
                    </li>
                    <li className="list-group-item">
                      Fiatalos, pörgős csapat és változatos projektek a kisebb
                      alkalmazásoktól a komplexebb felhő alapú rendszerekig
                    </li>
                    <li className="list-group-item">Rendszeres csapatépítők</li>
                  </ul>

                  <a
                    className="button button-primary button-ujarak"
                    href="mailto:career@mxcsoftware.it?subject=Állás"
                  >
                    Jelentkezz ITT
                  </a>
                </div>
              </article>
            </div>
          </div>
          {/* Frotnend junior */}
          {/* <div
          className="col-12 isotope-item wow fadeInRight"
          data-filter="frontend"
          data-wow-delay=".1s"
        >
          <div
            className="offset-xl-2 col-xl-8 wow fadeInRight"
            data-wow-delay=".1s"
          >
            <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
              <div>
                <h3 className="box-icon-modern-big-title">
                  Junior frontend fejlesztő
                </h3>
                <div className="box-icon-modern-decor"></div>
                <h4 className="text-left">Elvárások</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Fejlesztői végzettség vagy minimum 1 év szakmai tapasztalat
                  </li>
                </ul>
                <h4 className="text-left mt-5">Előnyök</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Angular és/vagy React tapasztalat
                  </li>
                  <li className="list-group-item">TypeScript tapasztalat</li>
                  <li className="list-group-item">
                    GIT, GitFlow alapos ismerete
                  </li>
                  <li className="list-group-item">
                    Bootstrap, Material, RESTful API, HTML5, CSS, JWT &
                    security, I18n, Yarn, NPM
                  </li>
                  <li className="list-group-item">
                    Unit és/vagy Component tesztelési tapasztalatok
                  </li>
                  <li className="list-group-item">
                    UI és/vagy UX tapasztalatok
                  </li>
                  <li className="list-group-item">
                    Bármely backend technológia ismerete és full-stack
                    gondolkodás
                  </li>
                </ul>
                <h4 className="text-left mt-5">Amit kínálunk</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Versenyképes fizetés és juttatások
                  </li>
                  <li className="list-group-item">
                    A fentieken kívül, pozíciótól függően éves/negyedéves
                    jutalom kerül kifizetésre, továbbá kollégáink
                    projektteljesítési bónuszokban is részesülhetnek
                  </li>
                  <li className="list-group-item">
                    Szakmai fejlődést maximálisan támogató céges folyamatok és
                    tapasztalt kollégáink nagy hangsúlyt fektetnek a
                    mentorálásra
                  </li>
                  <li className="list-group-item">
                    Rugalmas munkaidő és home office lehetőség
                  </li>
                  <li className="list-group-item">
                    Fiatalos, pörgős csapat és változatos projektek a kisebb
                    alkalmazásoktól a komplexebb felhő alapú rendszerekig
                  </li>
                  <li className="list-group-item">Rendszeres csapatépítők</li>
                </ul>

                <a
                  className="button button-primary button-ujarak"
                  href="mailto:career@mxcsoftware.it?subject=Állás"
                >
                  Jelentkezz ITT
                </a>
              </div>
            </article>
          </div>
        </div> */}

          {/* Mobile senior */}
          {/* <div
          className="col-12 isotope-item wow fadeInRight"
          data-filter="mobile"
          data-wow-delay=".1s"
        >
          <div
            className="offset-xl-2 col-xl-8 wow fadeInRight"
            data-wow-delay=".1s"
          >
            <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
              <div>
                <h3 className="box-icon-modern-big-title">
                  Senior mobil fejlesztő
                </h3>
                <div className="box-icon-modern-decor"></div>
                <h4 className="text-left">Elvárások</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Minimum 5 év szakmai tapasztalat Android és/vagy iOS
                    területeken (akár cross-platform technológiával is)
                  </li>
                  <li className="list-group-item">
                    Legalább 2 év Kotlin és/vagy Swift tapasztalat
                  </li>
                  <li className="list-group-item">
                    Rx platform specifikus könyvtárak ismerete
                  </li>
                  <li className="list-group-item">
                    Unit és/vagy Component tesztelési tapasztalatok
                  </li>
                  <li className="list-group-item">
                    GIT, GitFlow alapos ismerete
                  </li>
                </ul>
                <h4 className="text-left mt-5">Előnyök</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Cross-Platform technológia ismerete{" "}
                  </li>
                  <li className="list-group-item">
                    UI és/vagy UX tapasztalatok
                  </li>
                  <li className="list-group-item">
                    Csapatvezetési tapasztalatok
                  </li>
                  <li className="list-group-item">
                    Bármely backend technológia ismerete és full-stack
                    gondolkodás
                  </li>
                  <li className="list-group-item">
                    React Native és mobilfejlesztési tapasztalatok
                  </li>
                  <li className="list-group-item">DDD, CQRS ismeretek</li>
                  <li className="list-group-item">
                    Devops ismeretek (Azure Devops/Jenkins/GitLab)
                  </li>
                </ul>
                <h4 className="text-left mt-5">Amit kínálunk</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Versenyképes fizetés és juttatások
                  </li>
                  <li className="list-group-item">
                    A fentieken kívül, pozíciótól függően éves/negyedéves
                    jutalom kerül kifizetésre, továbbá kollégáink
                    projektteljesítési bónuszokban is részesülhetnek
                  </li>
                  <li className="list-group-item">
                    Szakmai fejlődést maximálisan támogató céges folyamatok és
                    tapasztalt kollégáink nagy hangsúlyt fektetnek a
                    mentorálásra
                  </li>
                  <li className="list-group-item">
                    Rugalmas munkaidő és home office lehetőség
                  </li>
                  <li className="list-group-item">
                    Fiatalos, pörgős csapat és változatos projektek a kisebb
                    alkalmazásoktól a komplexebb felhő alapú rendszerekig
                  </li>
                  <li className="list-group-item">Rendszeres csapatépítők</li>
                </ul>

                <a
                  className="button button-primary button-ujarak"
                  href="mailto:career@mxcsoftware.it?subject=Állás"
                >
                  Jelentkezz ITT
                </a>
              </div>
            </article>
          </div>
        </div> */}
          {/* Mobile medior */}
          {/* <div
          className="offset-xl-2 col-xl-8 isotope-item wow fadeInRight"
          data-filter="frontend"
          data-wow-delay=".1s"
        >
          <div className="col-sm-12 wow fadeInRight" data-wow-delay=".1s">
            <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
              <div>
                <h3 className="box-icon-modern-big-title">
                  Medior mobil fejlesztő
                </h3>
                <div className="box-icon-modern-decor"></div>
                <h4 className="text-left">Elvárások</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Minimum 3 év szakmai tapasztalat Android és/vagy iOS
                    területeken (akár cross-platform technológiával is)
                  </li>
                  <li className="list-group-item">
                    Legalább 1 év Kotlin és/vagy Swift tapasztalat
                  </li>
                  <li className="list-group-item">
                    Rx platform specifikus könyvtárak ismerete
                  </li>
                  <li className="list-group-item">
                    GIT, GitFlow alapos ismerete
                  </li>
                </ul>
                <h4 className="text-left mt-5">Előnyök</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Cross-Platform technológia ismerete
                  </li>
                  <li className="list-group-item">
                    Unit és/vagy Component tesztelési tapasztalatok
                  </li>
                  <li className="list-group-item">
                    UI és/vagy UX tapasztalatok
                  </li>
                  <li className="list-group-item">
                    Csapatvezetési tapasztalatok
                  </li>
                  <li className="list-group-item">
                    Bármely backend technológia ismerete és full-stack
                    gondolkodás
                  </li>
                  <li className="list-group-item">DDD, CQRS ismeretek</li>
                  <li className="list-group-item">
                    Devops ismeretek (Azure Devops/Jenkins/GitLab)
                  </li>
                </ul>
                <h4 className="text-left mt-5">Amit kínálunk</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Versenyképes fizetés és juttatások
                  </li>
                  <li className="list-group-item">
                    A fentieken kívül, pozíciótól függően éves/negyedéves
                    jutalom kerül kifizetésre, továbbá kollégáink
                    projektteljesítési bónuszokban is részesülhetnek
                  </li>
                  <li className="list-group-item">
                    Szakmai fejlődést maximálisan támogató céges folyamatok és
                    tapasztalt kollégáink nagy hangsúlyt fektetnek a
                    mentorálásra
                  </li>
                  <li className="list-group-item">
                    Rugalmas munkaidő és home office lehetőség
                  </li>
                  <li className="list-group-item">
                    Fiatalos, pörgős csapat és változatos projektek a kisebb
                    alkalmazásoktól a komplexebb felhő alapú rendszerekig
                  </li>
                  <li className="list-group-item">Rendszeres csapatépítők</li>
                </ul>

                <a
                  className="button button-primary button-ujarak"
                  href="mailto:career@mxcsoftware.it?subject=Állás"
                >
                  Jelentkezz ITT
                </a>
              </div>
            </article>
          </div>
        </div> */}
          {/* Mobil junior */}
          {/* <div
          className="col-12 isotope-item wow fadeInRight"
          data-filter="mobile"
          data-wow-delay=".1s"
        >
          <div
            className="offset-xl-2 col-xl-8 wow fadeInRight"
            data-wow-delay=".1s"
          >
            <article className="box-icon-modern box-icon-modern-custom box-icon-modern-full-width">
              <div>
                <h3 className="box-icon-modern-big-title">
                  Junior mobil fejlesztő
                </h3>
                <div className="box-icon-modern-decor"></div>
                <h4 className="text-left">Elvárások</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Fejlesztői végzettség vagy minimum 1 év szakmai tapasztalat
                  </li>
                </ul>
                <h4 className="text-left mt-5">Előnyök</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Cross-Platform technológia ismerete
                  </li>
                  <li className="list-group-item">
                    Unit és/vagy Component tesztelési tapasztalatok
                  </li>
                  <li className="list-group-item">
                    UI és/vagy UX tapasztalatok
                  </li>
                  <li className="list-group-item">
                    Bármely backend technológia ismerete és full-stack
                    gondolkodás
                  </li>
                  <li className="list-group-item">
                    GIT, GitFlow alapos ismerete
                  </li>
                </ul>
                <h4 className="text-left mt-5">Amit kínálunk</h4>
                <ul className="text-left ml-auto list-group list-group-flush">
                  <li className="list-group-item">
                    Versenyképes fizetés és juttatások
                  </li>
                  <li className="list-group-item">
                    A fentieken kívül, pozíciótól függően éves/negyedéves
                    jutalom kerül kifizetésre, továbbá kollégáink
                    projektteljesítési bónuszokban is részesülhetnek
                  </li>
                  <li className="list-group-item">
                    Szakmai fejlődést maximálisan támogató céges folyamatok és
                    tapasztalt kollégáink nagy hangsúlyt fektetnek a
                    mentorálásra
                  </li>
                  <li className="list-group-item">
                    Rugalmas munkaidő és home office lehetőség
                  </li>
                  <li className="list-group-item">
                    Fiatalos, pörgős csapat és változatos projektek a kisebb
                    alkalmazásoktól a komplexebb felhő alapú rendszerekig
                  </li>
                  <li className="list-group-item">Rendszeres csapatépítők</li>
                </ul>

                <a
                  className="button button-primary button-ujarak"
                  href="mailto:career@mxcsoftware.it?subject=Állás"
                >
                  Jelentkezz ITT
                </a>
              </div>
            </article>
          </div>
        </div> */}
        </div>
      </div>
    </section>
  )
}

export default CarrierPositions
